<template>
  <div>
    <div class="row">
      <div class="col-lg-12 col-md-12 col-sm-12">
        <c-table
          ref="table"
          title="설비 목록"
          :columns="grid.columns"
          :gridHeight="grid.height"
          :data="grid.data"
          :filtering="false"
          :columnSetting="false"
          :usePaging="false"
          :editable="editable"
          rowKey="equipmentCd"
          @rowClick="rowClick"
        >
        </c-table>
      </div>
      <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12 col-xl-12">
        <c-card title="설비별 점검결과 상세">
          <template slot="card-button">
          <q-btn-group outline >
            <c-btn label="설비별 점검결과 저장" icon="save" />
          </q-btn-group>
          </template>
          <template slot="card-detail">
            
            <div class="col-xs-12 col-sm-12 col-md-3 col-lg-3 col-xl-3">
              <c-card class="cardClassDetailForm" title="설비별 결과 기본정보"  bgClass="">
                <template slot="card-detail">
                  <div class="col-12">
                    <c-label-text title="사업장/점검부서" :value="result.plantName +'/' + result.deptNm"></c-label-text>
                  </div>
                  <div class="col-12">
                    <c-label-text title="설비유형/설비코드/설비명" :value="result.equipmentType + '/' + result.equipmentCd + '/' + result.equipmentNm"></c-label-text>
                  </div>
                  <div class="col-12">
                    <c-label-text title="점검유형" :value="result.safFacilityNm"></c-label-text>
                  </div>
                  <div class="col-12">
                    <c-label-text title="점검자" :value="result.chkUserNm"></c-label-text>
                  </div>
                  <div class="col-12">
                    <c-label-text title="점검완료일" :value="result.chkSchCompleYmd"></c-label-text>
                  </div>
                  <div class="col-12">
                    <c-label-text title="점검결과" :value="result.chkResultNm"></c-label-text>
                  </div>
                  <div class="col-12">
                    <c-label-text title="점검완료여부" :value="result.chkRsltYnNm"></c-label-text>
                  </div>
                  <div class="col-12">
                    <c-label-text title="점검결과요약" :value="result.chkRsltSmry"></c-label-text>
                  </div>
                </template>
              </c-card>
            </div>
            <div class="col-xs-12 col-sm-12 col-md-9 col-lg-9 col-xl-9">
              <div class="col-12">
                <c-table
                  ref="table"
                  title="설비별 점검항목"
                  :columns="grid2.columns"
                  :gridHeight="grid2.height"
                  :data="grid2.data"
                  :filtering="false"
                  :columnSetting="false"
                  :usePaging="false"
                  :hideBottom="true"
                  noDataLabel="설비에 대한 점검항목이 없습니다."
                  :editable="editable&&!param.disabled"
                  rowKey="equipmentCd"
                >
                </c-table>
              </div>
            </div>
          </template>
        </c-card>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'equipment-complete',
  props: {
    param: {
      type: Object,
      default: () => ({
        disabled: false,
      }),
    },
  },
  data() {
    return {
      editable: true,
      result: {
        plantName: '사업장1',
        deptNm: '안전환경팀',
        equipmentType: '집진설비',
        equipmentCd: 'F3-G938',
        equipmentNm: '집진설비 설비명',
        chkResult: '1',
        chkRsltYn: 'Y',
        chkSchYmd: '2021-07-24',
        chkRsltSmry: '점검결과요약',
        chkSchCompleYmd: '2021-07-25',
        safFacilityNm: '법정(정기)점검',
        chkUserNm: 'manager'
      },
      grid: {
        columns: [
          {
            name: 'plantName',
            field: 'plantName',
            label: '사업장',
            align: 'center',
            sortable: false
          },
          {
            name: 'deptNm',
            field: 'deptNm',
            label: '점검부서',
            align: 'center',
            sortable: false
          },
          {
            name: 'equipmentType',
            field: 'equipmentType',
            label: '설비유형',
            align: 'center',
            sortable: false
          },
          {
            name: 'equipmentNm',
            field: 'equipmentNm',
            label: '설비코드',
            align: 'center',
            sortable: false
          },
          {
            name: 'equipmentNm',
            field: 'equipmentNm',
            label: '설비명',
            align: 'center',
            style: 'width:100px',
            sortable: false
          },
          {
            name: 'safFacilityNm',
            field: 'safFacilityNm',
            label: '점검유형',
            align: 'center',
            style: 'width:100px',
            sortable: false,
          },
          {
            name: 'chkUserNm',
            field: 'chkUserNm',
            label: '점검자',
            align: 'center',
            style: 'width:120px',
            sortable: false,
          },
          {
            name: 'chkSchYmd',
            field: 'chkSchYmd',
            label: '점검예정일',
            align: 'center',
            style: 'width:80px',
            sortable: true,
          },
          {
            name: 'remark',
            field: 'remark',
            label: '비고',
            align: 'left',
            style: 'width:200px',
            sortable: false,
          },
          {
            name: 'chkSchCompleYmd',
            field: 'chkSchCompleYmd',
            label: '점검완료일',
            align: 'center',
            style: 'width:200px',
            sortable: true,
          },
          {
            name: 'chkYn',
            field: 'chkYn',
            label: '점검완료여부',
            align: 'center',
            style: 'width:200px',
            sortable: true,
          },
        ],
        data: [],
        height: '300px'
      },
      grid2: {
        columns: [
          {
            name: 'checkItemNm',
            field: 'checkItemNm',
            label: '점검항목',
            align: 'center',
            sortable: true
          },
          {
            name: 'checkResult',
            field: 'checkResult',
            label: '점검결과',
            align: 'center',
            sortable: true
          },
          {
            name: 'inspMethod',
            field: 'inspMethod',
            label: '검사방법',
            align: 'center',
            sortable: true
          },
          {
            name: 'criteria',
            field: 'criteria',
            label: '판정기준',
            align: 'center',
            sortable: true
          },
          {
            name: 'safetyInfo',
            field: 'safetyInfo',
            label: '점검시 안전사항',
            align: 'center',
            sortable: true
          },
        ],
        data: [],
        height: '650px'
      },
      chkResultItems: [],
    };
  },
  beforeCreate() {},
  created() {},
  beforeMount() {
    Object.assign(this.$data, this.$options.data());
  },
  mounted() {
    this.init();
  },
  beforeDestroy() {
  },
  methods: {
    init() {
      // role setting
      // url setting
      // code setting
      // list setting
      this.getList();
    },
    getList() {
      this.chkResultItems = [
        { code: '1', codeName: '적합' },
        { code: '2', codeName: '부적합' },
      ];
      this.chkRsltYnItems = [
        { code: 'Y', codeName: '완료' },
        { code: 'Y', codeName: '미완료' },
      ]
      this.grid.data = [
        { 
          plantName: '사업장1',
          deptNm: '안전환경팀',
          safFacilityNm: '자체(일상)점검',
          safFacilityCd: '1',
          equipmentType: '집진설비',
          equipmentNm: '집진설비 설비명',
          equipmentCd: 'F3-G938',
          chkSchYmd: '2021-07-24',
          chkSchCompleYmd: '2021-08-01',
          chkUserId: '1',
          chkUserNm: '홍씨',
          chkRsltYnNm: '완료',
          chkResultNm: '적합', 
          remark: '집진설비에 대한 비고',
          chkRsltSmry: '점검결과요약'
        },
        { 
          plantName: '사업장1',
          deptNm: '개발팀',
          safFacilityNm: '법정(자체)점검',
          safFacilityCd: '2',
          equipmentType: '소방설비',
          equipmentNm: '소방설비 설비명',
          equipmentCd: 'F3-G940',
          chkSchYmd: '2021-07-24',
          chkSchCompleYmd: '2021-08-01',
          chkUserId: '1',
          chkRsltYnNm: '완료',
          chkResultNm: '부적합', 
          chkUserNm: '김씨',
          remark: '소방설비에 대한 비고',
          chkRsltSmry: '소방설비에 대한 점검결과요약'
        },
      ];
      this.grid2.data = [
        { 
          checkItemNm: '점검항목1',
          checkResult: '점검결과1',
          inspMethod: '검사방법1',
          criteria: '판정기준1',
          safetyInfo: '점검시 안전사항1',
        },
        { 
          checkItemNm: '점검항목2',
          checkResult: '점검결과2',
          inspMethod: '검사방법2',
          criteria: '판정기준2',
          safetyInfo: '점검시 안전사항2',
        },
      ];
    },
    rowClick(row) {
      this.result = this.$_.clone(row);
    },
  }
};
</script>
